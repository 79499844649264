import React, { useContext } from "react";
import { toPng } from "html-to-image";
import html2pdf from "html2pdf.js";
import moment from "moment";
import UnitechFooter from "../image/certificate_footer.png";
import UnitechHeader from "../image/certificate_header.png";
import Logo from "../image/logo.png";
import {
  Autocomplete,
  Button,
  TableRow,
  Table,
  Grid,
  TextField,
  Typography,
  Toolbar,
} from "@mui/material";
import { makeChunks } from "../helper/helpers";
import MuiTableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/core/styles";
import { useStyles } from "../../constants/datasheetConstants.js";
import { DatasheetContext } from "./editDatasheet/datasheet.context";
import ObservedReadings from "./editDatasheet/ObservedReadings/ObservedReadings";
import DetailsOfCalStandards from "./editDatasheet/DetailsOfCalStandards";
import DownloadPdfLoaderModal from "../../utils/components/downloadPdfLoader";
import { datasheetsApprove } from "./editDatasheet/submitHandlers";

const TableCell = withStyles({
  root: {
    border: "none",
    padding: "0px",
    margin: "0px",
    lineHeight: "1.32",
    fontSize: "12px",
    paddingLeft: "5px",
  },
})(MuiTableCell);

export default function ViewDatasheet(props) {
  const printComponentRef = React.useRef();
  const classes = useStyles();
  const [downloadPdfloading, setDownlaodPdfLoading] =React.useState(false);
  const [letterHead, setLetterHead] = React.useState(true);
  const [electronicSign, setElectronicSign] = React.useState(true);
  const [signImg, setSignImg] = React.useState(false);
  const datasheetContextValue = useContext(DatasheetContext);
  const {
    state: {
      srfDetails,
      datasheetDetails,
      certificateDetails,
      disciplineDetails,
      calibratedBy,
      calibratedByImgSign,
      DatasheetReadingsAdditionalInfo,
      staticTables,
      datasheetApprovedBy,
      datasheetApprovedByImgSign,
      referenceData,
      instrumentDetails,
      settings
    },
  } = datasheetContextValue;
  const userType = localStorage.getItem("type");

  const generateDynamicFooter = async (
    pageNo,
    totalPages
  ) => {
    const formattedCurrentPage = pageNo.toString().padStart(2, "0");
    const formattedTotalPages = totalPages.toString().padStart(2, "0");
    const pageRange = `${formattedCurrentPage}/${formattedTotalPages}`;
    let htmlString = `
    <div style="padding:30px;">
    <table style="width:100%;">
        <tr>
         <td colspan="2" style="padding:5px;font-family: Calibri, sans-serif;line-height:25px;text-align:center">
          <b style="font-size:20px;">POOJA ENTERPRISES CALIBRATION LABORATORY</b>
        </td>
        </tr>
        <tr>       
            ${
              electronicSign
                ? `<td style="padding:5px;width:50%;text-align: left;font-family: Calibri, sans-serif;line-height:30px;">
                <b style="font-size:20px;">CALIBRATED BY <br> Electronically signed by </b>
                <br>
                <b style="font-size:19px;">${calibratedBy}<br>${moment(
                  datasheetDetails?.calibrationDate
                  ).format("DD/MM/yyyy hh:mm A")}</b>
            </td>`
                : `<td
                style="vertical-align: top;height:100px;padding:5px;width:50%;text-align: left;font-family: Calibri, sans-serif;line-height:30px;">
                <b style="font-size:20px;vertical-align: top;">CALIBRATED BY</b>
                <br>
                ${signImg ? 
                  `<div style="border-bottom:none;padding:5px 15px;height:80px;">
                    ${calibratedByImgSign ? `<img src="${calibratedByImgSign}" style="width:130px; height:100%;border:none;"/>` : ''}
                   </div>`
                  : ''} 
            </td>`
            }
            </td>
            ${
              electronicSign
                ? `<td style="padding:5px;width:50%;text-align: right;font-family: Calibri, sans-serif;line-height:30px;">
                <b style="font-size:20px;">APPROVED BY <br> Electronically signed by </b>
                <br>
                <b style="font-size:19px;">${calibratedBy}<br>${moment(
                  datasheetDetails?.calibrationDate
                  ).format("DD/MM/yyyy hh:mm A")}</b>
            </td>`
                : `<td
                style="vertical-align: top;height:100px;padding:5px;width:50%;text-align: right;font-family: Calibri, sans-serif;line-height:30px;">
                <b style="font-size:20px;vertical-align: top;">APPROVED BY</b>
                <br>
                ${signImg ? 
                  `<div style="border-bottom:none;padding:5px 15px;height:80px;">
                    ${datasheetApprovedByImgSign ? `<img src="${datasheetApprovedByImgSign}" style="width:130px; height:100%;border:none;"/>` : ''}
                   </div>`
                  : ''} 
            </td>`
            }
            </td>
           
        </tr>
        <tr>
        <td colspan="2" style="padding:5px;font-family: Calibri, sans-serif;line-height:25px;text-align:right">
            <b style="font-size:20px;">Page Number : </b>
            <span style="font-size:19px;">${pageRange}</span>
          </td>
        </tr>
    </table>
</div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 1, scale: 1 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };
  const generateLetterHeadFooter = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechFooter} style="width:100%; height:150px;"/>
    </div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 1, scale: 1 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };
  const generatePDF = async () => {
    setDownlaodPdfLoading(true);
    try{
    const element = printComponentRef.current;
    let pdf = await html2pdf()
      .from(element)
      .set({
        margin: [10, 1, 90, 1],
        filename: `datasheet${props.datasheetId}.pdf`,
        pagebreak: { after: "section", mode: ["css", "legacy"] },
        html2canvas: {
          dpi: 192,
          scale: 2.5,
          letterRendering: true,
          useCORS: true,
        },
        image: { type: 'png', quality: 0.4 },
        jsPDF: {
          orientation: "portrait",
          unit: "pt",
          format: "a4",
          compress: true,
        },
      })
      .toPdf()
      .get("pdf");

    const totalPages = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      pdf.setPage(i);
      pdf.setFontSize(9);
      pdf.setTextColor(0, 0, 0);
      pdf.setFont("Courier");

       // border add for contnet
       const borderWidth = 0.6;
       const leftMargin = 15;
       const borderX = leftMargin;
       const borderY = 5;
       const rightMargin = 595 - leftMargin * 0.95;
       pdf.setLineWidth(borderWidth);
       pdf.rect(borderX, borderY, rightMargin - leftMargin, 750);

      let image = await generateDynamicFooter(
        i,
        totalPages
      );
      pdf.addImage(image, 0, 760, 595, 85);

      if (electronicSign) {
        pdf.setFontSize(9);
        const text =
          "This datasheet is electronically signed and does not required physical signature.";
        const textWidth =
          (pdf.getStringUnitWidth(text) * pdf.internal.getFontSize()) /
          pdf.internal.scaleFactor;
        const pageWidth = pdf.internal.pageSize.getWidth();
        const x = (pageWidth - textWidth) / 2;
        pdf.text(text, x, 765);
      }

      // if (letterHead) {
      //   image = await generateLetterHeadFooter();
      //   pdf.addImage(image, 0, 772, 595, 70);
      // }
    }

    const blob = pdf.output("blob");
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${datasheetDetails?.clientName}_${props.datasheetId}.pdf`;
    a.click();

    setDownlaodPdfLoading(false);
  } catch (error) {
    console.error('Error generating PDF:', error);
    setDownlaodPdfLoading(false);
  }
  };

  function headerrowDetailsTable() {
    return (
      <table className="standardUnitTable " align="left">
        <tr>
          <td rowSpan="4" style={{ borderRight: "none" }}>
            <img src={Logo} height="60px" />
          </td>
          <td rowSpan="4" style={{ borderRight: "none" }}>
            <b style={{ fontSize: "14px" }}>
            POOJA ENTERPRISES CALIBRATION LABORATORY <br />
              Observation Sheet - {referenceData?.srfInstruments?.requestedDucName || datasheetDetails?.requestedname || datasheetDetails?.instrumentName}
            </b>
          </td>
        </tr>
        <tr>
          <td style={{ borderRight: "none", borderBottom: "none" }}>
            <b>Document Number</b>
          </td>
          <td style={{ borderBottom: "none" }}>{disciplineDetails?.documentNumber}</td>
        </tr>
        <tr>
          <td style={{ borderRight: "none", borderBottom: "none" }}>
            <b>Issue No. / Date</b>
          </td>
          <td style={{ borderBottom: "none" }}>
            {disciplineDetails?.issueNo} / {moment(disciplineDetails?.issueDate).format("DD/MM/YYYY")}
          </td>
        </tr>
        <tr>
          <td style={{ borderRight: "none" }}>
            <b>Amendment No. / Date</b>
          </td>
          <td>
            {disciplineDetails?.amendmentNo} /{" "}
            {moment(disciplineDetails?.amendmentDate).format("DD/MM/YYYY")}
          </td>
        </tr>
      </table>
    );
  }

  function customerDetailsTable() {
    return (
      <table className="standardUnitTable single-border-table" align="center">
        <tr>
          <td style={{ width: "16.66%" }}>
            <b>Purpose</b>
          </td>
          <td style={{ width: "16.66%" }}>Calibration</td>
          <td style={{ width: "16.66%" }}>
            <b>SRF Number</b>
          </td>
          <td style={{ width: "16.66%" }}>{srfDetails?.srfNumber}</td>
          <td style={{ width: "16.66%" }}>
            <b>SRF Date</b>
          </td>
          <td style={{ width: "16.66%" }}>
            {moment(srfDetails?.entryDate).format("DD/MM/YYYY")}
          </td>
        </tr>
        <tr>
          <td style={{ width: "16.66%" }}>
            <b>Certificate Number</b>
          </td>
          <td style={{ width: "16.66%" }}>{certificateDetails?.certificateNumber}</td>
          <td style={{ width: "16.66%" }}>
            <b>Date of Calibration</b>
          </td>
          <td style={{ width: "16.66%" }}>
            {moment(datasheetDetails?.calibrationDate).format("DD/MM/YYYY")}
          </td>
          <td style={{ width: "16.66%" }}>
            <b>Due Date of Calibration</b>
          </td>
          <td style={{ width: "16.66%" }}>
          {datasheetDetails?.calibrationFrequency == "Not Required" ? "-" : moment(datasheetDetails?.nextDueDate).format("DD/MM/YYYY")}
          </td>
        </tr>
      </table>
    );
  }

  function unitCalibrationDetails() {
    return (
      <Table
        className={classes.table}
        style={{ fontSize: "11px", marginBottom: "3px" }}
      >
        <TableRow className={classes.row}>
          <TableCell className={classes.infoCell} align="center">
            <TableCell
              style={{
                border: "none",
                width: "150px",
                padding: "0px",
              }}
            >
              <b>Name of Instrument </b>
            </TableCell>
            <TableCell style={{ border: "none" }}>:</TableCell>
            <TableCell style={{ border: "none" }}>
              {referenceData?.srfInstruments?.requestedDucName || datasheetDetails?.requestedname || datasheetDetails?.instrumentName}
            </TableCell>
          </TableCell>
          {datasheetDetails?.make &&
          <TableCell className={classes.infoCell}>
            <TableRow style={{ border: "none", padding: "0px" }}>
              <TableCell
                style={{
                  border: "none",
                  width: "150px",
                  padding: "0px",
                }}
              >
                <b>Make</b>
              </TableCell>
              <TableCell style={{ border: "none" }}>:</TableCell>
              <TableCell style={{ border: "none" }}>{datasheetDetails?.make}</TableCell>
            </TableRow>
          </TableCell>
          }
        </TableRow>
        <TableRow>
          {datasheetDetails?.DUCID &&
          <TableCell className={classes.infoCell}>
            <TableRow style={{ border: "none", padding: "0px" }}>
              <TableCell
                style={{
                  border: "none",
                  width: "150px",
                  padding: "0px",
                }}
              >
                <b>Identification No.</b>
              </TableCell>
              <TableCell style={{ border: "none" }}>:</TableCell>
              <TableCell style={{ border: "none" }}>{datasheetDetails?.DUCID}</TableCell>
            </TableRow>
          </TableCell>
          }
          {datasheetDetails?.model &&
          <TableCell className={classes.infoCell}>
            <TableRow style={{ border: "none", padding: "0px" }}>
              <TableCell
                style={{
                  border: "none",
                  width: "150px",
                  padding: "0px",
                }}
              >
                <b>Model</b>
              </TableCell>
              <TableCell style={{ border: "none" }}>:</TableCell>
              <TableCell style={{ border: "none" }}>{datasheetDetails?.model}</TableCell>
            </TableRow>
          </TableCell>
          }
        </TableRow>
        <TableRow>
          {datasheetDetails?.serialNo &&
          <TableCell className={classes.infoCell}>
            <TableRow style={{ border: "none", padding: "0px" }}>
              <TableCell
                style={{
                  border: "none",
                  width: "150px",
                  padding: "0px",
                }}
              >
                <b>Serial no. </b>
              </TableCell>
              <TableCell style={{ border: "none" }}>:</TableCell>
              <TableCell style={{ border: "none" }}>{datasheetDetails?.serialNo}</TableCell>
            </TableRow>
          </TableCell>
          }          
          {datasheetDetails?.ranges &&
          <TableCell className={classes.infoCell}>
            <TableRow style={{ border: "none", padding: "0px" }}>
              <TableCell
                style={{
                  border: "none",
                  width: "150px",
                  padding: "0px",
                }}
              >
                <b>Range</b>
              </TableCell>
              <TableCell style={{ border: "none" }}>:</TableCell>
              <TableCell style={{ border: "none" }}>
                {datasheetDetails?.ranges
                  ?.replaceAll("||", ", ")
                  ?.replaceAll("|", " to ")
                  ?.replaceAll("#", " ")}
              </TableCell>
            </TableRow>
          </TableCell>
          }
        </TableRow>
        <TableRow>
          {datasheetDetails?.accuracy &&
         <TableCell className={classes.infoCell}>
           <TableRow style={{ border: "none", padding: "0px" }}>
             <TableCell
               style={{
                 border: "none",
                 width: "150px",
                 padding: "0px",
               }}
             >
               <b>Accuracy</b>
             </TableCell>
             <TableCell style={{ border: "none" }}>:</TableCell>
             <TableCell style={{ border: "none" }}>
               {datasheetDetails?.accuracy
                 ? `± ${datasheetDetails?.accuracy
                     ?.replaceAll("||", ", ")
                     ?.replaceAll("|", " to ")
                     ?.replaceAll("#", " ")}`
                 : ""}
             </TableCell>
           </TableRow>
         </TableCell>
          }
         {datasheetDetails?.lc &&
         <TableCell className={classes.infoCell}>
           <TableRow style={{ border: "none", padding: "0px" }}>
             <TableCell
               style={{
                 border: "none",
                 width: "150px",
                 padding: "0px",
               }}
             >
               <b>Resolution/Least Count</b>
             </TableCell>
             <TableCell style={{ border: "none" }}>:</TableCell>
             <TableCell style={{ border: "none" }}>
               {datasheetDetails?.lc
                 ?.replaceAll("||", ", ")
                 ?.replaceAll("|", " to ")
                 ?.replaceAll("#", " ")}
             </TableCell>
           </TableRow>
         </TableCell>
         }
       </TableRow>
       <TableRow>
        {datasheetDetails?.locationOfInstrument &&
         <TableCell className={classes.infoCell}>
           <TableRow style={{ border: "none", padding: "0px" }}>
             <TableCell
               style={{
                 border: "none",
                 width: "150px",
                 padding: "0px",
               }}
             >
               <b>Location</b>
             </TableCell>
             <TableCell style={{ border: "none" }}>:</TableCell>
             <TableCell style={{ border: "none" }}>
               {datasheetDetails?.locationOfInstrument ? datasheetDetails?.locationOfInstrument : ""}
             </TableCell>
           </TableRow>
         </TableCell>
        }
         {datasheetDetails?.location && 
         <TableCell className={classes.infoCell}>
           <TableRow style={{ border: "none", padding: "0px" }}>
             <TableCell
               style={{
                 border: "none",
                 width: "150px",
                 padding: "0px",
               }}
             >
               <b>Calibration Location </b>
             </TableCell>
             <TableCell style={{ border: "none" }}>:</TableCell>
             <TableCell style={{ border: "none" }}>{datasheetDetails?.location}</TableCell>
           </TableRow>
         </TableCell>
         }
       </TableRow>
       <TableRow>
        {datasheetDetails?.conOfDuc &&
         <TableCell className={classes.infoCell}>
           <TableRow style={{ border: "none", padding: "0px" }}>
             <TableCell
               style={{
                 border: "none",
                 width: "150px",
                 padding: "0px",
               }}
             >
               <b>Condition of Instrument </b>
             </TableCell>
             <TableCell style={{ border: "none" }}>:</TableCell>
             <TableCell style={{ border: "none" }}>{datasheetDetails?.conOfDuc}</TableCell>
           </TableRow>
         </TableCell>
        }
        {datasheetDetails?.calibrationProcedureNo &&
         <TableCell className={classes.infoCell}>
           <TableRow style={{ border: "none", padding: "0px" }}>
             <TableCell
               style={{
                 border: "none",
                 width: "150px",
                 padding: "0px",
               }}
             >
               <b>Calibration Procedure</b>
             </TableCell>
             <TableCell style={{ border: "none" }}>:</TableCell>
             <TableCell style={{ border: "none" }}>
               {datasheetDetails?.calibrationProcedureNo}
             </TableCell>
           </TableRow>
         </TableCell>
        }
       </TableRow>
       {(datasheetDetails?.referenceStandards || datasheetDetails?.isaStandard) &&
       <TableRow>
         <TableCell className={classes.infoCell}>
           <TableRow style={{ border: "none", padding: "0px" }}>
             <TableCell
               style={{
                 border: "none",
                 width: "150px",
                 padding: "0px",
               }}
             >
               <b>Standard Reference</b>
             </TableCell>
             <TableCell style={{ border: "none" }}>:</TableCell>
             <TableCell style={{ border: "none" }}>
               {datasheetDetails?.isaStandard ? datasheetDetails?.isaStandard : datasheetDetails?.referenceStandards}
             </TableCell>
           </TableRow>
         </TableCell>
       </TableRow>
       }
        {datasheetDetails?.extraColumns && datasheetDetails?.extraColumns?.map((e, index) => (
            index % 2 === 0 && e[0] && e[1] &&(
              <TableRow key={index}>
                <TableCell className={classes.infoCell}>
                  <TableRow style={{ border: "none", padding: "0px" }}>
                    <TableCell
                      style={{
                        border: "none",
                        width: "150px",
                        padding: "0px",
                        textTransform:"capitalize"
                      }}
                    >
                      <b>{e?.[0] || ""}</b>
                    </TableCell>
                    <TableCell style={{ border: "none" }}>:</TableCell>
                    <TableCell style={{ border: "none" }}>{e?.[1] || ""}</TableCell>
                  </TableRow>
                </TableCell>

                {datasheetDetails.extraColumns[index + 1] && datasheetDetails.extraColumns[index + 1][0] && datasheetDetails.extraColumns[index + 1][1] && (
                  <TableCell className={classes.infoCell}>
                    <TableRow style={{ border: "none", padding: "0px" }}>
                      <TableCell
                        style={{
                          border: "none",
                          width: "150px",
                          padding: "0px",
                          textTransform:"capitalize"
                        }}
                      >
                        <b>{datasheetDetails.extraColumns[index + 1]?.[0] || ""}</b>
                      </TableCell>
                      <TableCell style={{ border: "none" }}>:</TableCell>
                      <TableCell style={{ border: "none" }}>{datasheetDetails.extraColumns[index + 1]?.[1] || ""}</TableCell>
                    </TableRow>
                  </TableCell>
                )}
              </TableRow>
            )
          ))}
      </Table>
    );
  }

  return (
    <>
    {
      datasheetApprovedBy && 
      <Toolbar style={{ padding: "0px", width: "95%", margin: "20px auto" }}>
        <Grid container spacing={2} align="left">
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <Autocomplete
              size="small"
              id="combo-box-demo"
              options={[
                { id: 1, label: "With Letter Head" },
                { id: 2, label: "Without Letter Head" },
              ]}
              renderInput={(params) => (
                <TextField {...params} label="letterHead" />
              )}
              onInputChange={(event, newInputValue) => {
                if (
                  newInputValue === "With Letter Head" ||
                  newInputValue === ""
                ) {
                  setLetterHead(true);
                } else if (newInputValue === "Without Letter Head") {
                  setLetterHead(false);
                }
                // newInputValue == "Approve" ? setEnableDisable() : setEnableDisable(4+':'+result.toString());
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <Autocomplete
              size="small"
              id="combo-box-demo"
              options={[
                { id: 1, label: "With Electronically Signed" },
                { id: 3, label: "Signature Image" },
                { id: 2, label: "Without  Electronically Signed" },
              ]}
              renderInput={(params) => (
                <TextField {...params} label="Electronically Signed" />
              )}
              onInputChange={(event, newInputValue) => {
                if (
                  newInputValue === "With Electronically Signed" ||
                  newInputValue === ""
                ) {
                  setElectronicSign(true);
                } else if (newInputValue === "Without  Electronically Signed") {
                  setElectronicSign(false);
                  setSignImg(false);
              }
              else if (
                newInputValue === "Signature Image"
              ) {
                  setElectronicSign(false);
                  setSignImg(true);
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3}>
            <Button
              variant="contained"
              size="small"
              // sx={{ ml: 3 }}
              onClick={() => {
                generatePDF();
              }}
            >
              Download PDF
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
    }    
      <div id="printOnly2" ref={printComponentRef}>
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td style={{ border: "none" }}>
                <div
                  className="content"
                  style={{
                    fontSize: "11px",
                    margin: "20px",
                    marginTop: "0px",
                    marginBottom: "0px",
                    padding: "10px",
                    // border: "1px solid black",
                  }}
                >
                  <Table>
                    {headerrowDetailsTable()}
                    <h5 style={{ textAlign: "center", marginBottom: "5px" }}>
                      <b>
                        <u>OBSERVATION SHEET</u>
                      </b>
                    </h5>
                    {customerDetailsTable()}
                    <TableRow>
                      <TableCell colSpan={2} align="center">
                        <Typography variant="body1" component="div">
                          Details of Unit Under Calibration(UUC)
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {unitCalibrationDetails()}
                    <Table
                      className={classes.table}
                      style={{ marginBottom: "3px" }}
                    >
                      <TableRow>
                        <TableCell>
                          <b>Environmental Conditions: </b>
                        </TableCell>
                        <TableCell>
                          <span>
                            <b style={{ paddingRight: "10px" }}>
                              Temperature :
                            </b>
                            {datasheetDetails?.startTemp?.includes('°C')
                            ? datasheetDetails?.startTemp?.replace('#', ' ')
                            : datasheetDetails?.startTemp + ' °C'}
                          </span>
                        </TableCell>
                        <TableCell>
                          <span>
                            <b style={{ paddingRight: "10px" }}>Humidity:</b>
                            {datasheetDetails?.startHumidity?.includes('%RH')
                            ? datasheetDetails?.startHumidity?.replace('#', ' ')
                            : datasheetDetails?.startHumidity + ' %RH'}
                          </span>
                        </TableCell>
                      </TableRow>
                    </Table>
                    <DetailsOfCalStandards isPrinting={props.isPrinting} />
                  </Table>
                  <div>
                    <div style={{ textAlign: "left" }}>
                      <div style={{ marginBottom: "5px", marginTop: "10px" }}>
                        <b>DISCIPLINE : </b>
                        {datasheetDetails?.disciplineName}
                      </div>
                      <ObservedReadings datasheetId={props.datasheetId} isPrinting={props.isPrinting} />
                    </div>
                  </div>
                  
                    {DatasheetReadingsAdditionalInfo?.length > 0 && (
                    <div style={{width:"100%", margin:"auto",pageBreakBefore:"always"}}>
                        <Grid spacing={2}>
                          {DatasheetReadingsAdditionalInfo?.sort((a, b) => a.rowIndex - b.rowIndex)?.map(
                            (item, index) => {
                              const {
                                tableId,
                                datasheetReadingId,
                                rowIndex,
                                ...rest
                              } = item;
                              const matchingTable = tableId
                                ? staticTables?.find(
                                    (table) => table?.id === parseInt(tableId)
                                  )
                                : null;

                              if (!matchingTable) return null;

                              const filteredEntries = Object?.entries(
                                rest
                              )?.filter(
                                ([key, value]) =>
                                  value !== null &&
                                  ![
                                    "rowIndex",
                                    "status",
                                    "lastModified",
                                  ]?.includes(key)
                              );

                              return (
                                // <Grid
                                //   key={index}
                                //   item
                                //   xs={12}
                                //   style={{ marginBottom: "20px" }}
                                // >
                                  <Grid key={index} container spacing={1} style={{ marginBottom: "20px" }}>
                                    {filteredEntries?.map(([key, value]) => {
                                      const imageData = value
                                        ? JSON?.parse(value)
                                        : {};

                                      return (
                                        <Grid
                                          key={key}
                                          item
                                          xs={12}
                                          md={6}
                                          lg={3}                                          
                                        >
                                          <div style={{pageBreakInside:"avoid"}}>
                                            <div style={{display:"flex",flexDirection:"column"}}>
                                            <b>{`Table Name:${matchingTable?.tableName}`}</b>
                                            <b>{`Column Name:${
                                              matchingTable[key] || key
                                            }`}</b>
                                            <b>{`Row Index:${rowIndex}`}</b>
                                            </div>
                                          <div
                                            style={{
                                              width: "100%",
                                              height: "200px"
                                            }}
                                          >
                                            <img
                                              src={imageData?.image}
                                              alt={`Image of ${key}`}
                                              style={{
                                                width: "100%",
                                                height: "100%",
                                              }}
                                            />
                                          </div>
                                          </div>
                                        </Grid>
                                      );
                                    })}
                                  </Grid>
                                // </Grid>
                              );
                            }
                          )}
                        </Grid>
                        <hr
                          style={{
                            border: "1px solid black",
                            width: "100%",
                          }}
                        />
                    </div>
                    )}

                    <div style={{pageBreakInside:"avoid", marginTop:"-8px"}}>
                    <div style={{border:"1px solid black", textAlign:"left", fontSize:"12px", padding:"2px"}}>
                      <b>Remarks</b><br/>
                      <span style={{whiteSpace:"pre-wrap"}}>
                      {settings?.["Compliance Status"] && settings?.["Compliance Status"].toLowerCase() === "true" ? `The results ${datasheetDetails?.complianceStatus == 1 ? "" : "doesn't" } comply with acceptance criteria.\n`: ""}
                      {datasheetDetails?.configuration?.remark ||
                        datasheetDetails?.defaultReamrk ||
                        instrumentDetails?.instrumentRemark || ""}
                      </span>
                    </div>
                    </div>
                  <div style={{ textAlign: "center" }}>
                    --------- END OF OBSERVATION SHEET ---------
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div style={{ width: "100%" }} id="header-section"></div>
      </div>
      {!datasheetApprovedBy && calibratedBy && userType !=3 && (
        <div style={{ padding: "2rem 0rem", textAlign: "left" }}>
          <Button
            variant="contained"
            color="success"
            size="small"
            sx={{ ml: 3 }}
            onClick={() => {
              datasheetsApprove(props.datasheetId);
            }}
          >
            Approve
          </Button>
        </div>
      )}

      {downloadPdfloading && <DownloadPdfLoaderModal loading={downloadPdfloading} name={"Datasheet"} />}
    </>
  );
}
